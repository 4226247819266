import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { PostingHelper } from "../../../../../Helpers/PostingHelper";
import { useParams } from "react-router-dom";
import {
  Rule,
  RulesBySkillId,
} from "../../../../../Modules/CollectiveAgreement/Types";
import { CollectiveAgreementRulesTable } from "../../../../../Modules/CollectiveAgreement/Components/Table";
import { PageLoader } from "../../../../Parts/General/PageLoader";
import { PageNotFound } from "../../PageNotFound";

interface IProps {}
export const PostingCollectiveAgreementPage = ({}: IProps) => {
  const postingHelper = new PostingHelper();
  const { postingId, skillId } = useParams<{
    postingId: string;
    skillId: string;
  }>();

  const [rules, setRules] = useState<Rule[]>();

  useEffect(() => {
    postingHelper.getPosting(postingId).then((resp) => {
      const allRulesParsed = JSON.parse(
        resp.CollectiveAgreementRules
      ) as RulesBySkillId[];
      const rulesBySkill = allRulesParsed
        .find((x) => x.skillId === skillId)
        ?.rules.map<Rule>((x) => ({
          ...x,
          value: (Number.parseInt(x.value) / 100).toFixed(2),
        }));
      setRules(rulesBySkill);
    });
  }, [postingId]);

  if (rules === undefined) return <PageLoader />;

  if (rules?.length === 0) return <PageNotFound />;

  return (
    <div className="searchPostings">
      <div className="margin-top-24">
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <h1 className="inline-block">Collective agreement rules</h1>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </div>
      <CollectiveAgreementRulesTable rules={rules ?? []} />
    </div>
  );
};
