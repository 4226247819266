import React from "react";
import { Button } from "@material-ui/core";
import "./PredefinedTemplates.scss";

export const PredefinedTemplates = ({
  onClick,
}: {
  onClick: (selected: string) => void;
}) => {
  const templates = [
    "Unfortunately, we are unable to hire employees with limited work permits as we cannot ensure overtime across multiple employers. If you have any questions, please contact us at info@chabber.com",
    "You need to write your national ID-number (CPR- number) in your profile. Go to “settings” -> “payment details”. Then upload your ID document again. Thanks",
    "Kindly upload the document again all 4 corners need to be visible. You can adjust the size when it’s uploaded by pulling the corners.",
    "Please change your citizenship, so you can upload all required documents. You can change this under 'personal data'.",
    "Vil du uploade billedet igen, hvor alle 4 hjørner af kortet er synlige? Du kan justere beskæringen når du uploader.",
    "Du skal tilføje dit CPR-nr. i din profil, det gør du under ”lønoplysninger”, derefter upload dokumentet igen.",
    "Upload venligst et gyldigt og fysisk pas eller kørekort.",
    "Please upload a valid passport",
    "Billedet er lidt utydeligt, venligst upload igen.",
  ];
  return (
    <div className="predefined-templates">
      {templates.map((template) => (
        <Button
          className="predefined-templates__btn"
          size="small"
          variant="contained"
          onClick={() => onClick(template)}
        >
          {template}
        </Button>
      ))}
    </div>
  );
};
