// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import {
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import {
  BlogView,
  Home,
  Login,
  AdminPageNotFound,
  AtlasNav,
  AdminUserView,
  AdminNav,
  AdminUsersView,
  SitemapView,
  Features,
  LanguageView,
  CommunicationView,
  CommunicationEventView,
  CmsMetaView,
  CmsEdit,
  RunSalaryView,
  Courses,
  Course,
  StyleGuideView,
  SettingsView,
  BlogOverview,
  Organizer,
  EditCoursePage,
  CourseView,
} from "./Components/Routing/Admin";
import {
  Frontpage,
  FrontpageFreelancer,
  MainLogin,
  Register,
  ForgotPassword,
  ResetPassword,
  AwaitingVerificaton,
  ChangePassword,
  FreelancerSettingsBasic,
  FreelancerSettingsBank,
  FreelancerSettingsResume,
  Users,
  Favourites,
  Postings,
  Archive,
  Notifications,
  Contract,
  CreateUser,
  SearchPostings,
  SearchPostingsNoLogin,
  FreelancerFavourites,
  Checkout,
  Shifts,
  Shift,
  ShiftErrorBoundary,
  AboutUs,
  EventsSegment,
  KitchensSegment,
  PrivateFunctionsSegment,
  FeaturesCompany,
  EventCaseVenues,
  FreelancerSettingsVerification,
  FreelancerMobileSettings,
  Courses as FreelancerCourses,
  Course as FreelancerCourse,
  CourseAttendance,
  PageNotFound,
  UnsubscribeViaMail,
} from "./Components/Routing/Facade";
import {
  EditPosting,
  RegisterProfile,
  CompanySettingsBasic,
  CompanySettingsPayment,
  CompanySettingsProfile,
  ActivateByMail,
  FreelancerHome,
} from "./Components/Routing/Facade";
import { CompanyCreateUser } from "./Components/Routing/Facade/Company/CompanyCreateUser";
import "./app.scss";
import "./setup.scss";
import "./variables.scss";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles";
import PrivateAdminRoute from "./Components/Parts/General/PrivateAdminRoute";
import PageRoute from "./Components/Parts/General/PageRoute";
import { AdminUserHelper } from "./Helpers/AdminUserHelper";
import { CountryHelper } from "./Helpers/CountryHelper";
import { StorageHelper } from "./Helpers/StorageHelper";
import { CompanyNav } from "./Components/Routing/Facade/Navigation/CompanyNav";
import { isAdminLoggedIn, getCurrentUser } from "./Helpers/SessionHelper";
import { FreelancerNav } from "./Components/Routing/Facade/Navigation/FreelancerNav";
import { UserHelper } from "./Helpers/UserHelper";
import history from "./Helpers/History";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Pricing } from "./Components/Routing/Facade/Home/info/Pricing";
import Axios from "axios";
import { Header } from "./Components/Routing/Facade/Navigation/Header";
import { CallManagerView } from "./Helpers/CallManager/CallManagerView";
import { Hidden, SwipeableDrawer } from "@material-ui/core";
import { SideBarOpenSingleton } from "./Helpers/SideBarOpenSingleton";
import { EventWithPayload } from "./Common/EventWithPayload";
import { ErrorBoundary } from "./Components/Parts/General/ErrorBoundary";
import { VerificationView } from "./Components/Routing/Admin/Verification";
import { VerificationUser } from "./Components/Routing/Admin/VerificationUser";
import ScrollToTop from "./Components/Parts/General/ScrollToTop";
import Intercom from "react-intercom";

import ReactGA from "react-ga";

import ReactPixel from "react-facebook-pixel";
import { PrivateNav } from "./Components/Routing/Facade/Navigation/PrivatNav";
import Posting from "./Components/Routing/Facade/Company/Posting/Posting";

import SnackbarManager from "./Helpers/SnackbarManager/SnackbarManager";
// import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import { SnackbarProvider } from "notistack";
import { RouteHandler } from "./Helpers/RouteHandler";
import { CallManager } from "./Helpers/CallManager/CallManager";
import { getQuery } from "./Helpers/queryHelper";
import { BroadcastHelper } from "./Helpers/BroadcastHelper";
import { CompanySettingsSalaryRules } from "./Components/Routing/Facade/Company/CompanySettings/CompanySettingsSalaryRules/CompanySettingsSalaryRules";
import { CompanySettingsInvoices } from "./Components/Routing/Facade/Company/CompanySettings/CompanySettingsInvoices/CompanySettingsInvoices";
import { CollectiveAgreementRulesView } from "./Components/Routing/Admin/CollectiveAgreementRulesView";
import { StopAccountDelete } from "./Components/Routing/Facade/Freelancer/StopAccountDelete";
import TagManager from "react-gtm-module";
import { PostingCollectiveAgreementPage } from "./Components/Routing/Facade/Freelancer/PostingCollectiveAgreement";

const tagManagerArgs = {
  gtmId: "GTM-PFS6XGF",
};

TagManager.initialize(tagManagerArgs);

const sentryHealtCheckKey = "/admin/sentry-health-check";

// ReactGA.initialize('UA-85987420-1');
// ReactGA.pageview(window.location.pathname + window.location.search);
// history.listen(({ location }: any) => ReactGA.pageview(location.pathname + location.search));

ReactPixel.init("291964221222055");
history.listen(() => ReactPixel.pageView());
ReactPixel.pageView();

interface IState {
  viewAtlas: boolean;
  sideBarOpen: boolean;
  intercomHidden: boolean;
}

interface IProps extends RouteComponentProps {}

class App extends Component<IProps, IState> {
  adminUserHelper = new AdminUserHelper();
  countryHelper = new CountryHelper();
  storageHelper = new StorageHelper();
  userHelper = new UserHelper();

  // @ts-ignore
  snackElement: SnackbarProvider;
  routeHandler = new RouteHandler();
  broadcastHelper = new BroadcastHelper(history);

  options: ThemeOptions = {
    palette: {
      primary: { main: "#f79747" },
      secondary: { main: "#495057" },
      error: { main: "#FF4A51" },
    },
    spacing: 8,
    typography: {
      htmlFontSize: 10,
      fontFamily: [
        "proxima-soft",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  };
  theme = createTheme(this.options);

  constructor(props: IProps) {
    super(props);

    this.state = {
      viewAtlas: new StorageHelper().getAtlasFromLocal(),
      sideBarOpen: SideBarOpenSingleton.Instance.getSideBar(),
      intercomHidden: false,
    };

    this.toggleAtlas = this.toggleAtlas.bind(this);
    this.loggedInAdminEvent = this.loggedInAdminEvent.bind(this);
    this.facadeLanguageChanged = this.facadeLanguageChanged.bind(this);
    this.verifyAdminLogin = this.verifyAdminLogin.bind(this);
    this.attemptLogin = this.attemptLogin.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
    this.toggleDrawerOpen = this.toggleDrawerOpen.bind(this);
    this.toggleDrawerClose = this.toggleDrawerClose.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.toggleIntercom = this.toggleIntercom.bind(this);

    this.verifyAdminLogin();
  }

  async verifyAdminLogin() {
    let token = await this.adminUserHelper.verifyLoggedIn();
    if (token) {
      this.storageHelper.setAdminToken(token);
    } else {
      this.storageHelper.removeAdminToken();
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("adminLoggedInEvent", this.loggedInAdminEvent);
    window.addEventListener("facadeLanguageEvent", this.facadeLanguageChanged);
    window.addEventListener("callManagerEvent", (e) => {
      console.log("something happened", e);
    });
    window.addEventListener("toggleSidebar", this.toggleSideBar);
    window.addEventListener("trackEvent", this.trackEvent);
    window.addEventListener("toggleIntercom", (e) =>
      this.toggleIntercom((e as EventWithPayload).payload)
    );
  }

  async toggleSideBar() {
    let sideBarOpen = SideBarOpenSingleton.Instance.getSideBar();
    await this.setState({ sideBarOpen });
  }

  async componentDidMount() {
    this.isMobile();
    Axios.defaults.baseURL = "/";
    this.attemptLogin();
    this.storageHelper.initializeCaches(false);
    CallManager.Instance.historyObject = history;

    const reloadScript = document.createElement("script");
    reloadScript.src = "customjs/cookiebot.js";
    document.body.appendChild(reloadScript);

    const script = document.createElement("script");

    script.src = "https://consent.cookiebot.com/uc.js";
    //@ts-ignore
    script.setAttribute("data-cbid", "92694b0d-19d6-41b1-9022-15ba2a415c76");
    script.id = "Cookiebot";
    //@ts-ignore
    script.setAttribute("data-blockingmode", "none");
    script.async = true;

    document.body.appendChild(script);
  }

  async attemptLogin() {
    if (window.location.href.includes("/admin/")) return;
    if (window.location.href.includes("/login")) return;

    let reqirectpath = getQuery("redirect", history.location.search);
    this.routeHandler.checkRouting(history, undefined, reqirectpath);
  }

  facadeLanguageChanged() {
    this.forceUpdate();
  }

  loggedInAdminEvent() {
    if (isAdminLoggedIn()) {
      this.forceUpdate();
    }
  }

  trackEvent(event: any) {
    ReactGA.event({
      category: event.payload.event,
      action: event.payload.data,
    });
    ReactPixel.trackCustom(event.payload.event, event.payload.data);
  }

  toggleAtlas() {
    new StorageHelper().setAtlasInLocal((!this.state.viewAtlas).toString());
    this.setState((previous) => ({ viewAtlas: !previous.viewAtlas }));
  }

  toggleDrawerOpen() {
    SideBarOpenSingleton.Instance.toggleSideBar();
    this.setState({ sideBarOpen: SideBarOpenSingleton.Instance.getSideBar() });
  }

  toggleDrawerClose() {
    SideBarOpenSingleton.Instance.toggleSideBar();
    this.setState({ sideBarOpen: SideBarOpenSingleton.Instance.getSideBar() });
  }

  toggleIntercom(shouldHide?: any) {
    if (shouldHide != null) {
      this.setState({ intercomHidden: shouldHide });
    } else {
      this.setState({ intercomHidden: !this.state.intercomHidden });
    }
  }

  isMobile = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.setState({ intercomHidden: true });
    } else {
      this.setState({ intercomHidden: false });
    }
  };

  renderIntercom() {
    if (new StorageHelper().getEnvVar() !== "Production") {
      return;
    }

    let isAdmin = history.location.pathname.includes("/admin");

    if (this.state.intercomHidden === true || isAdmin === true) return;
    let currentUser = getCurrentUser();

    let intercomUser: {
      user_id: undefined | string;
      email: undefined | string;
      name: string;
      phone: undefined | string;
      domaintag: "new site";
    } = {
      user_id: undefined,
      email: undefined,
      name: "guest",
      phone: undefined,
      domaintag: "new site",
    };

    if (currentUser) {
      intercomUser.user_id = currentUser.Id;
      intercomUser.email = currentUser.Email;
      intercomUser.phone = currentUser.LoginPhone;

      if (currentUser.Company != null) {
        intercomUser.name = currentUser.Company.CompanyName;
      } else {
        intercomUser.name = currentUser.Firstnames + " " + currentUser.Lastname;
      }
    }
    return (
      <div className="app">
        {<Intercom appID="w7inyu80" {...intercomUser} />}
      </div>
    );
  }

  render() {
    let adminMenues: any = <div></div>;
    if (isAdminLoggedIn()) {
      adminMenues = (
        <div>
          <AtlasNav />
          <AdminNav
            toggleAtlas={this.toggleAtlas}
            toggleAtlasIcon={this.state.viewAtlas}
            // @ts-ignore
            history={history}
            {...this.props}
          />
        </div>
      );
    }

    let currentUser = getCurrentUser();

    return (
      <MuiThemeProvider theme={this.theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            hideIconVariant
            maxSnack={3}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            className="snackbar"
            classes={{
              variantSuccess: "snackbar_success",
              variantError: "snackbar_error",
              variantWarning: "snackbar_warning",
              variantInfo: "snackbar_info",
              containerAnchorOriginTopRight: "snackbarContainer",
            }}
          >
            <ErrorBoundary>
              <CallManagerView />
              <SnackbarManager />
              {this.renderIntercom()}
              <BrowserRouter
                // @ts-ignore
                history={history}
              >
                <ScrollToTop>
                  <Switch>
                    <Route
                      sensitive
                      path="/:slug1*:slug2([A-ZÄÖÜÆØÅ]):slug3*/"
                      render={(props) => (
                        <Redirect
                          to={`${
                            props.location.pathname.toLowerCase() +
                            props.location.search
                          }`}
                        />
                      )}
                    />

                    <Route path="/admin">
                      <div
                        className={
                          this.state.viewAtlas
                            ? "wrapper adminWrapper atlasActive"
                            : "wrapper adminWrapper"
                        }
                      >
                        <div className="bodyContent">
                          {adminMenues}
                          <Switch>
                            <PrivateAdminRoute
                              path="/admin/employees"
                              component={AdminUsersView}
                            />
                            <PrivateAdminRoute
                              path="/admin/employee"
                              component={AdminUserView}
                            />
                            <PrivateAdminRoute
                              path="/admin/sitemap"
                              component={SitemapView}
                            />
                            <PrivateAdminRoute
                              path="/admin/features"
                              component={Features}
                            />
                            <PrivateAdminRoute
                              path="/admin/language"
                              component={LanguageView}
                            />
                            <PrivateAdminRoute
                              path="/admin/communication"
                              component={CommunicationView}
                            />
                            <PrivateAdminRoute
                              path="/admin/communicationevent"
                              component={CommunicationEventView}
                            />
                            <PrivateAdminRoute
                              path="/admin/blogmeta"
                              component={CmsMetaView}
                            />
                            <PrivateAdminRoute
                              path="/admin/coursemeta"
                              component={CmsMetaView}
                            />
                            <PrivateAdminRoute
                              path="/admin/cmsedit"
                              component={CmsEdit}
                            />
                            <PrivateAdminRoute
                              path="/admin/runsalary"
                              component={RunSalaryView}
                            />
                            <PrivateAdminRoute
                              path="/admin/collective-agreement"
                              component={CollectiveAgreementRulesView}
                            />
                            <PrivateAdminRoute
                              path="/admin/courses"
                              component={Courses}
                            />
                            <PrivateAdminRoute
                              path="/admin/course"
                              component={Course}
                            />
                            <PrivateAdminRoute
                              path="/admin/organizer"
                              component={Organizer}
                            />
                            <PrivateAdminRoute
                              path="/admin/settings"
                              component={SettingsView}
                            />
                            <PrivateAdminRoute
                              path="/admin/styleguide"
                              component={StyleGuideView}
                            />
                            <PrivateAdminRoute
                              path="/admin/verification"
                              component={VerificationView}
                            />
                            <PrivateAdminRoute
                              path="/admin/verificationuser"
                              component={VerificationUser}
                            />
                            <PrivateAdminRoute
                              path={sentryHealtCheckKey}
                              component={
                                <button
                                  onClick={() => {
                                    throw new Error(sentryHealtCheckKey);
                                  }}
                                >
                                  Test sentry
                                </button>
                              }
                            />
                            <Route
                              path="/admin/login"
                              render={(props) => <Login {...props} />}
                            />
                            <PrivateAdminRoute
                              exact
                              path="/admin"
                              component={Home}
                            />
                            <Route component={AdminPageNotFound} />
                          </Switch>
                        </div>
                      </div>
                    </Route>

                    <Route path="/company">
                      <div className="wrapper">
                        <div className="bodyContent">
                          <Hidden only="xs">
                            {currentUser &&
                            currentUser.Company &&
                            currentUser.Company.IsPrivate ? (
                              <Route component={PrivateNav} />
                            ) : (
                              <Route component={CompanyNav} />
                            )}
                          </Hidden>
                          <Hidden smUp>
                            {/* Open this drawer when clickeing the mobilesidebartrigger */}
                            <SwipeableDrawer
                              anchor="left"
                              variant="temporary"
                              open={this.state.sideBarOpen}
                              onOpen={this.toggleDrawerOpen}
                              onClose={this.toggleDrawerClose}
                              classes={{ paper: "companyMenuPaper Paper" }}
                            >
                              {currentUser &&
                              currentUser.Company &&
                              currentUser.Company.IsPrivate ? (
                                <Route component={PrivateNav} />
                              ) : (
                                <Route component={CompanyNav} />
                              )}
                            </SwipeableDrawer>
                          </Hidden>
                          <Switch>
                            <PageRoute
                              path="/company/editposting"
                              component={EditPosting}
                            />
                            <Route
                              path="/company/redirecttoeditposting"
                              render={(props) => (
                                <Redirect
                                  to={"/company/editposting"}
                                  {...props}
                                />
                              )}
                            />
                            <PageRoute
                              path="/company/postings"
                              component={Postings}
                            />
                            <PageRoute
                              path="/company/archive"
                              component={Archive}
                            />
                            <PageRoute
                              path="/company/posting"
                              component={Posting}
                            />
                            <PageRoute
                              path="/company/checkout"
                              component={Checkout}
                            />
                            <PageRoute
                              path="/company/users"
                              component={Users}
                            />
                            <PageRoute
                              path="/company/favourites"
                              component={Favourites}
                            />
                            <PageRoute
                              path="/company/settings/basic"
                              component={CompanySettingsBasic}
                            />
                            <PageRoute
                              path="/company/settings/payment"
                              component={CompanySettingsPayment}
                            />
                            <PageRoute
                              path="/company/settings/profile"
                              component={CompanySettingsProfile}
                            />
                            <PageRoute
                              path="/company/settings/changepassword"
                              component={ChangePassword}
                            />
                            <PageRoute
                              path="/company/settings/salaryrules"
                              component={CompanySettingsSalaryRules}
                            />
                            <PageRoute
                              path="/company/settings/invoices"
                              component={CompanySettingsInvoices}
                            />
                            <PageRoute
                              path="/company/companycreateuser"
                              component={CompanyCreateUser}
                            />
                            <PageRoute component={PageNotFound} />
                          </Switch>
                        </div>
                      </div>
                    </Route>

                    <Route path="/freelancer">
                      <div className="wrapper">
                        <Route component={FreelancerNav} />
                        <div className="bodyContent">
                          <Switch>
                            <PageRoute
                              path="/freelancer/profile"
                              component={FreelancerHome}
                            />
                            <PageRoute
                              path="/freelancer/shifts"
                              component={(props: IProps) => (
                                <Shifts {...props} archive={false} />
                              )}
                              keyPage={1}
                            />
                            <PageRoute
                              path="/freelancer/archive"
                              component={(props: IProps) => (
                                <Shifts {...props} archive={true} />
                              )}
                              keyPage={2}
                            />
                            <PageRoute
                              path="/freelancer/shift"
                              component={(props: IProps) => (
                                <ShiftErrorBoundary>
                                  <Shift {...props} />
                                </ShiftErrorBoundary>
                              )}
                            />
                            <PageRoute
                              path="/freelancer/contract"
                              component={Contract}
                            />
                            <PageRoute
                              path="/freelancer/settings/basic"
                              component={FreelancerSettingsBasic}
                            />
                            <PageRoute
                              path="/freelancer/settings/bank"
                              component={FreelancerSettingsBank}
                            />
                            <PageRoute
                              path="/freelancer/settings/resume"
                              component={FreelancerSettingsResume}
                            />
                            <PageRoute
                              path="/freelancer/settings/verification"
                              component={FreelancerSettingsVerification}
                            />
                            <PageRoute
                              path="/freelancer/notifications"
                              component={Notifications}
                            />
                            <PageRoute
                              path="/freelancer/searchpostings"
                              component={SearchPostings}
                            />
                            <PageRoute
                              path="/freelancer/posting/:postingId/collective-agreement/:skillId"
                              component={PostingCollectiveAgreementPage}
                            />
                            <PageRoute
                              path="/freelancer/settings/changepassword"
                              component={ChangePassword}
                            />
                            <PageRoute
                              path="/freelancer/favourites"
                              component={FreelancerFavourites}
                            />
                            <PageRoute
                              path="/freelancer/settings"
                              component={FreelancerMobileSettings}
                            />
                            <PageRoute
                              path="/freelancer/courses"
                              component={FreelancerCourses}
                            />
                            <PageRoute
                              path="/freelancer/course"
                              component={FreelancerCourse}
                            />
                            <PageRoute component={PageNotFound} />
                          </Switch>
                        </div>
                      </div>
                    </Route>

                    <Route>
                      <div>
                        <Header />
                        <Switch>
                          <Route exact path="/blog" component={BlogView} />
                          <Route
                            exact
                            path="/overview"
                            component={BlogOverview}
                          />
                          <Route
                            exact
                            path="/createuser"
                            component={CreateUser}
                          />
                          <Route
                            exact
                            path="/login"
                            render={(props) => <MainLogin {...props} />}
                          />
                          <Route exact path="/register" component={Register} />
                          <Route
                            exact
                            path="/registerfreelancer"
                            component={RegisterProfile}
                          />
                          <Route
                            exact
                            path="/registercompany"
                            component={RegisterProfile}
                          />
                          <Route
                            exact
                            path="/registerprivate"
                            component={RegisterProfile}
                          />
                          <Route
                            exact
                            path="/forgotpassword"
                            component={ForgotPassword}
                          />
                          <Route
                            exact
                            path="/resetpassword"
                            component={ResetPassword}
                          />
                          <Route
                            exact
                            path="/verification"
                            component={AwaitingVerificaton}
                          />
                          <Route
                            exact
                            path="/lookingforwork"
                            component={FrontpageFreelancer}
                          />
                          <Route exact path="/aboutus" component={AboutUs} />
                          <Route exact path="/pricing" component={Pricing} />
                          <Route
                            exact
                            path="/events"
                            component={EventsSegment}
                          />
                          <Route
                            exact
                            path="/kitchens"
                            component={KitchensSegment}
                          />
                          <Route
                            exact
                            path="/features"
                            component={FeaturesCompany}
                          />
                          <Route
                            exact
                            path="/cases/venues"
                            component={EventCaseVenues}
                          />
                          <Route
                            exact
                            path="/privatefunctions"
                            component={PrivateFunctionsSegment}
                          />
                          <Route
                            exact
                            path="/activatebyemail"
                            component={ActivateByMail}
                          />
                          <Route exact path="/" component={Frontpage} />
                          <Route
                            exact
                            path="/courseattendance"
                            component={CourseAttendance}
                          />
                          <Route exact path="/course" component={CourseView} />
                          <Route
                            exact
                            path="/searchpostings"
                            component={SearchPostingsNoLogin}
                          ></Route>
                          <Route
                            exact
                            path="/unsubscribe"
                            component={UnsubscribeViaMail}
                          ></Route>
                          <Route
                            exact
                            path="/stopaccountdelete/:id"
                            component={StopAccountDelete}
                          />
                          <Route component={PageNotFound}></Route>
                        </Switch>
                      </div>
                    </Route>
                  </Switch>
                </ScrollToTop>
              </BrowserRouter>
            </ErrorBoundary>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}
export default App;
